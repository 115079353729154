import React from 'react'
import Page from '../components/Page'

export default () => (
  <Page
    idx={0}
    body={
      <div>
        <p>Katie & Rocky · June 7, 2019</p>
      </div>
    }
    hideTitle
  />
)
